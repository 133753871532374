import { __decorate } from "tslib";
// Watch
import { Component, Vue } from 'vue-property-decorator';
import { userToolStore, contaBancariaStore, contaBancariaFormaPagamentoStore, } from '@/store';
import _ from 'lodash';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Agência', value: 'agencia' },
            { text: 'Conta', value: 'conta' },
            { text: 'Banco', value: 'banco.nome' },
            { text: 'Nome', value: 'nome' },
            { text: 'Forma pgto para conferência caixa', value: 'conta_padrao' },
            { text: 'Saldo', value: 'saldo' },
            { text: 'Ativa', value: 'is_active' },
            { text: 'Ações', value: 'actions' },
        ];
        this.loading = true;
        this.search = '';
        this.selectedItem = {};
        this.somenteAtivos = true;
    }
    get allowView() {
        if (userToolStore.userToolsIndexed[`Contas bancárias`]) {
            return userToolStore.userToolsIndexed[`Contas bancárias`][0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed[`Contas bancárias`]) {
            return userToolStore.userToolsIndexed[`Contas bancárias`][0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed[`Contas bancárias`]) {
            return userToolStore.userToolsIndexed[`Contas bancárias`][0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed[`Contas bancárias`]) {
            return userToolStore.userToolsIndexed[`Contas bancárias`][0].allow_delete;
        }
    }
    get contasBancarias() {
        return contaBancariaStore.contasBancarias;
    }
    get contasBancariasFiltered() {
        return _.filter(this.contasBancarias, (item) => {
            return this.somenteAtivos ? item.is_active : true;
        });
    }
    get contasBancariasFormasDePagamento() {
        return contaBancariaFormaPagamentoStore.contasBancariasFormasPagamentos;
    }
    formasPagamentoConta(contaBancaria) {
        return _.filter(this.contasBancariasFormasDePagamento, (item) => item.conta_bancaria_id === contaBancaria.id);
    }
    async deleteItem(id) {
        this.$swal({
            title: 'Confirma a exclusão da conta bancária?',
            text: 'Após exclusão a conta bancária não pode ser recuperada!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                // @ts-ignore
                await contaBancariaStore.deleteContaBancaria(id);
                // @ts-ignore
                await contaBancariaStore.getContasBancarias();
                this.loading = false;
            }
        });
    }
    async mounted() {
        this.loading = true;
        await contaBancariaStore.getContasBancarias();
        await contaBancariaFormaPagamentoStore.getContasBancariasFormasPagamentos();
        this.loading = false;
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-contas-bancarias-edit', params: { id } });
    }
};
List = __decorate([
    Component
], List);
export default List;
